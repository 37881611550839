import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PickList, PickListItem } from '../../../models/Picklist';
import PickListService from '../../../services/PickListService';
import PicklistItemEditor from '../../../components/picklist-builder/PicklistItemEditor';
import StaticBreadCrumb from '../../../components/shared/breadcumb/StaticBreadCrumb';
import InlineEditor from '../../../components/shared/form-control/InlineEditor';
import PageLoader from '../../../components/shared/page-loader/PageLoader';
import TopNavPortal from '../../../components/layout/top-menu/TopNavPortal';
import PicklistEditorPortal from '../../../components/picklist-builder/PicklistEditorPortal';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from '../Layout';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useTopNavHeading } from '../../../global-state/Workspace';
import { useCurrentTenantId } from '../../../global-state/Clients';

const PicklistDetails: FC = () => {
  const { picklistId } = useParams<{ picklistId: string }>();
  const [picklist, setPicklist] = useState<PickList | null>(null);
  const {
    t,
    i18n: { language: userLanguage },
  } = useTranslation('navigation');
  const setTopNavheading = useTopNavHeading((x) => x.setValue);
  const { clientId } = useParams<{ clientId: string }>();
  const currentTenantId = useCurrentTenantId((x) => x.value);

  useEffect(() => {
    setTopNavheading(t('page.picklist-details'));
    return () => {
      setTopNavheading('');
    };
  }, [setTopNavheading, t]);

  const getPicklist = useCallback(() => {
    PickListService.getPickList(picklistId as string, true).then((res) => {
      setPicklist(res.data);
    });
  }, [picklistId]);

  useEffect(() => {
    getPicklist();
  }, [getPicklist]);

  const updatePicklist = (picklistName: string) => {
    if (picklist) {
      if (!picklist.translations[userLanguage]) {
        picklist.translations[userLanguage] = {};
      }
      picklist.translations![userLanguage]!.name = picklistName;

      PickListService.update(picklist);
    }
  };

  const sortItems = (newSortOrder: PickListItem[]) => {
    if (!picklist) {
      return;
    }

    setPicklist((prevPicklist) => ({
      ...(prevPicklist || ({} as PickList)),
      items: newSortOrder,
    }));

    PickListService.updateSortOrder(
      picklist.id,
      newSortOrder.map((item) => item.id),
    );
  };

  return (
    <AdminLayout>
      <div className="bg-background-1 flex min-h-full flex-col">
        <PageLoader loading={!picklist}>
          <TopNavPortal>
            <StaticBreadCrumb
              currentStepName={LanguageUtils.getTranslation('name', picklist?.translations ?? {})}
              breadCrumbs={[
                {
                  name: t('page.picklist-builder'),
                  path: clientId ? `/clients/${clientId}/organisation#client-picklists` : `/builder/${currentTenantId}#picklists`,
                },
              ]}
            ></StaticBreadCrumb>
          </TopNavPortal>
          <div className="border-gray-5 h-0 flex-grow overflow-y-auto border-t">
            <div className="p-6 pb-0">
              <div className="flex items-center justify-between">
                <div className="w-full pr-4">
                  <InlineEditor
                    data-cy="pickist-name"
                    value={LanguageUtils.getTranslation('name', picklist?.translations || {})}
                    placeholder={LanguageUtils.getTranslation('name', picklist?.translations ?? {}) || 'Picklist name'}
                    onChange={updatePicklist}
                    size="text-dpm-32"
                  />
                </div>
                <div className="flex justify-between gap-4">
                  <div id="picklist-detail-button-portal"></div>
                </div>
              </div>
              <div>{LanguageUtils.getTranslation('description', picklist?.translations ?? {})}</div>
            </div>
            <div className="bg-background-1 p-6">
              {picklist && (
                <PicklistItemEditor picklist={picklist} onItemChanged={getPicklist} onSort={sortItems} buttonWrapper={PicklistEditorPortal} />
              )}
            </div>
          </div>
        </PageLoader>
      </div>
    </AdminLayout>
  );
};

export default PicklistDetails;
